import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Samhold – Aktiviteter – Opplevelse',
    paragraph: "OPP og UT ble startet 28/11 2012 av IntOps veteraner og pårørende for å komme seg “over” den berømte “dørstokkmila" +

        "Det var også ønske fra alle involverte at vi laget et tilbud som var uforpliktende og som var fritt og gratis å delta på (uten medlemskontingent)." +
        "Her skulle alle kunne komme og inkluderes uavhengig hvilken misjon de hadde deltatt i, og uavhengig av hvilken veteranorganisasjon de tilhørte – dette har vi fått til pr. d.d."
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">

                  </div>
                <h3 className="mt-0 mb-12">
                  Bilder fra Sommercampen 2019
                  </h3>
                <p className="m-0">
                  Vel nå ca. 8 år etterpå så har noen tusen deltatt på våre turer og arrangementer. Og hver sommer arrangerer det som nå er blitt Norges største camp for veteraner og veteranfamilier. Tilbudet vårt er åpen for IntOps veteraner, pårørende, etterlatte og stadigtjenestegjørende veteraner m/familie. OPP og UT er “brukerstyrt” og har ingen medlemmer. (NB; Alle våre turer og arr. er alkoholfrie) Vi ble registrert i Brønnøysund og frivilligregisteret i 2016.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/sommerkamp.jpg')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Arild Lihaug (venstre) Og Bjørn Dåvøy
                </div>
                <h3 className="mt-0 mb-12">
                 Sentral siden starten
                </h3>
                <p className="m-0">
                  To som har vært sentral i arbeidet fra starten, er Arild Lihaug (venstre) Og Bjørn Dåvøy som fikk forsvarets veteranpris i 2018 «-Sammen har de to over flere år gjort en ekstraordinær innsats for veteransaken.» I begrunnelsen fra veteranprisrådet trekkes også frem deres auksjoner og salg av veterankalenderen, som årlig har samlet inn hundretusenvis av kroner til Stiftelsen Veteranhjelp.
                </p>
              </div>
              <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                )}
                   data-reveal-container=".split-item">
                <Image
                    src={require('./../../assets/images/ml2018-1505.jpg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
              </div>
            </div>
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">

                  </div>
                <h3 className="mt-0 mb-12">
                 Fantastisk gjeng!
                  </h3>
                <p className="m-0">
                  Takket være en fantastisk gjeng, har vi i løpet av 2019 delt ut 4200 kg med mat som ellers ville gått til spille. De som ikke har hatt anledning til å hente selv, har fått tilbud om tilkjøring. En stor takk til alle som har vært med på å gjøre dette mulig!
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/75580319_2641691725921370_2755808247276371968_o.jpg')}
                  alt="Features split 02"
                  width={528}
                  height={100} />
              </div>
            </div>



          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
