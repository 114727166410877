import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Om oss',
    paragraph: 'Vi ble dannet i 2012. Alle våre arrangement er åpne og gratis for IntOps veteraner, pårørende, etterlatte og stadig tjenestegjørende veteraner m/familie. Alle våre aktiviteter og arrangementer er alkoholfrie.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-01.svg')}
                      alt="Features tile icon 01"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Om oss
                    </h4>
                  <p className="m-0 text-sm">
                    Vi ble dannet i 2012. Alle våre arrangement er åpne og gratis for IntOps veteraner, pårørende, etterlatte og stadig tjenestegjørende veteraner m/familie. Alle våre aktiviteter og arrangementer er alkoholfrie.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-02.svg')}
                      alt="Features tile icon 02"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Ønsker du å støtte oss?
                    </h4>
                  <p className="m-0 text-sm">
                    Vi har grasrotandel nr: 916132468. Donasjonskonto nr: 3411.39.82707. Eller du kan enkelt Vippse til vårt nr: 130523
                    På forhånd tusen takk for støtten
                    BIA
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-03.svg')}
                      alt="Features tile icon 03"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Vår Facebook
                    </h4>
                  <p className="m-0 text-sm">
                    Vi holder våre følgere oppdatert om aktiviteter og arrangementer via våre sider på facebook. Er du på facebook, anbefaler vi at du følger oss der. For de som ikke har facebook, har vi lagt de siste innleggene fra facebooksiden vår i en nyhetsstrøm under.
                    </p>
                </div>
              </div>
              <div id="fb-root"/>
              <script async defer crossOrigin="anonymous"
                      src="https://connect.facebook.net/nb_NO/sdk.js#xfbml=1&version=v8.0" nonce="fvrrBl8W"/>
            </div>
            <iframe
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fintopsbergen%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=false&hide_cover=false&show_facepile=true&appId"
                width="375" height="500"  scrolling="no" frameBorder="0"
                allowTransparency="true" allow="encrypted-media"/>
          </div>
        </div>
      </div>

    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
