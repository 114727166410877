import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import { render } from 'react-dom';
import Gallery from 'react-grid-gallery';
const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: '',
    paragraph: ''
  };
  const IMAGES =
      [{
        src: "https://i.imgur.com/fWBo7rP.jpg",
        thumbnail: "https://i.imgur.com/fWBo7rP.jpg",
        thumbnailWidth: 300,
        thumbnailHeight: 174,
        caption: "Bilder fra sommerkampen 2019"

      },
        {
          src: "https://i.imgur.com/zqltcqL.jpg",
          thumbnail: "https://i.imgur.com/zqltcqL.jpg",
          thumbnailWidth: 300,
          thumbnailHeight: 212,
        },
        {
          src: "https://i.imgur.com/0ITeDyz.jpg",
          thumbnail: "https://i.imgur.com/0ITeDyz.jpg",
          thumbnailWidth: 300,
          thumbnailHeight: 212,
        },
        {
          src: "https://i.imgur.com/BOps6MJ.jpg",
          thumbnail: "https://i.imgur.com/BOps6MJ.jpg",
          thumbnailWidth: 300,
          thumbnailHeight: 212,


        }]
  return (
      <section
          {...props}
          className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />





              <Gallery images={IMAGES}  backdropClosesModal={true}  maxRows={2}/>



          </div>
        </div>
      </section>

  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
